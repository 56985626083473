<script>
import Nav from "@/components/Nav/index.vue";
export default {
    name: "Benefits",
    components: {
        Nav,
    },
    methods: {
        sendGtm(str) {
            window.dataLayer.push({
                event: "horyuken_event",
                event_name: str,
                event_time: new Date().toLocaleString(),
            });
        },
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
